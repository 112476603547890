import React from "react"
import styled, { keyframes } from "styled-components"
import logo from "../images/logo.png"
import groupImg from "../images/group.png"
import bannerCurve from "../images/banner-curve.png"
import Header from "./Header"
import { Link } from "gatsby"
// import Particles from "react-particles-js"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { fadeIn } from "react-animations"
import { Button } from "../components"

const HeroBanner = props => (
  <Container id="home">
    <CenterContainer>
      <InnerContainer>
        {/* <Logo src={logo} /> */}
        {/* <Logo
            title="Desafio BLockchain logo"
            alt="Desafio Blockchain Logo"
            fluid={props.data.logo.childImageSharp.fluid}
          /> */}
        <Title>La función debe continuar</Title>
        <br />
        <Text>
          La incertidumbre nos hace actuar <br />
          <strong>#NoSomosInvisibles</strong>
          <br />
          <strong>#YoTambienCreo</strong>
        </Text>
        <br/><br/>
        <Link to="#info">
          <HeroButton>Ver mas</HeroButton>
        </Link>
      </InnerContainer>
      <ImageContainer>
        <Illustration
          title="Grupode artistas"
          alt="Grupo de artistas unido"
          // fluid={props.data.blockchain.childImageSharp.fluid}
          src={groupImg}
        />
      </ImageContainer>
    </CenterContainer>
    {/* <HeaderFooter src={bannerCurve} alt="Footer image effect" /> */}
  </Container>
)

const HeroButton = styled(Button)`
  padding: 16px 24px;    
`;

const fadeInAnimation = keyframes`${fadeIn}`

const CenterContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 160px 32px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: space-between;
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 16px;
  }
`

// const HeroParticles = styled(Particles)`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   opacity: 0.5;
//   @media (max-width: 600px) {
//     opacity: 0.2;
//   }
// `
const Container = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 600px;
  max-height: 940px;
  background: white;
  z-index: 999;
  display: flex;
  position: relative;
  background: white;
  overflow: hidden;
  @media(max-width: 640px){
    padding-top: 72px;
  }
`
const InnerContainer = styled.div`
  flex: 1;
  opacity: 0;
  animation: 1s ${fadeInAnimation} forwards;
  animation-delay: 0.7s;
  z-index: 1;
  @media(max-width: 640px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -300px;
  }
`
const Logo = styled(Img)`
  width: 100%;
  max-width: 500px;
  margin-bottom: 3rem;
  @media (max-width: 600px) {
    margin-bottom: 1rem;
  }
`
const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 45px;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 18px;
    opacity: 0;
  }
`
const Subtitle = styled.h2`
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`
const Text = styled.p`
  color: rgba(0, 0, 0, 0.8);
  font-weight: 200;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`

const ImageContainer = styled.div`
  width: 50%;
  left: 50px;
  position: relative;
  opacity: 0;
  animation: 0.3s ${fadeInAnimation} forwards;
  animation-delay: 0.3s;
  @media (max-width: 600px) {
    bottom: 0px;
    width: 100%;
    margin: auto;
    display: block;
    /* margin-bottom: 16px; */
    position: absolute;
    transform: none;
  }
`
const Illustration = styled.img`
  max-width: 100%;
  margin: auto;
  display: block;
  transform: scale(2) translateY(40%) translateX(-70px);
  padding-right: 24px;
  @media(max-width: 600px){
    padding-right: 0px;
    transform: scale(1.9) translateY(20%) translateX(10px);
  }

`
const HeaderFooter = styled.img`
  position: absolute;
  bottom: -1px;
  width: 100%;
  pointer-events: none;
`

export default HeroBanner
