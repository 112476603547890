import * as React from "react"
import styled, {css} from "styled-components"
import * as Yup from "yup"
import {
  LinearProgress,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core"
import { Formik, Form, Field } from "formik"
import { TextField, CheckboxWithLabel, Select } from "formik-material-ui"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { Button } from "../components"
const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($data: LeadCreateInput!) {
    createLead(data: $data) {
      id
      name
      email
      phoneNumber
      type
      country
      phoneNumber
      problem
      solution
      participates
    }
  }
`
const RegistrationForm = () => {
  const [success, setSuccess] = React.useState()
  const [isArtist, setIsArtist] = React.useState(
    typeof window != "undefined" && !window.location.href.includes("interesado")
  )

  React.useEffect(() => {
    const handler = () => setIsArtist(window.location.href.includes("artista"))

    window.onhashchange = handler
  }, [])
  const [createLead] = useMutation(CREATE_LEAD_MUTATION)
  const handleCreate = async data => {
    console.log("CREATE", { data })
    const newLead = await createLead({ variables: { data } })
    setSuccess(newLead)
    return newLead
  }
  return (
    <Container>
      <span id="interesado" />
      <span id="artista" />
      <CenterContainer>
        {isArtist ? (
          <Title>Recibi ayuda</Title>
        ) : (
          <Title id="interesado">Sumate a colaborar</Title>
        )}

        {isArtist ? (
          <Text>
            Queremos amplificar la voz de los artistas de latinoamérica. <br />
            ¿Cómo funciona? <br />
            <br />
            Te estaremos contactando por correo y por teléfono. Vamos a evaluar
            tu problemática y encontrar similitudes con otros artistas. Nos
            conectaremos virtualmente para idear soluciones.
          </Text>
        ) : (
          <Text>
            Queremos visibilizar el sector creativo y juntarnos a pensar
            virtualmente cómo podemos hacer que la función continue.
            <br />
            <br /> ¡Aportá tu idea! Registrate y creemos un laboratorio de ideas
            basado en colaboración e innovación.
            <br />
            <br /> ¿Te gustaría ayudar a llevarla adelante? Te estaremos
            contactando por correo y por teléfono para definir el alcance.
            <br />
            <br /> ¡Gracias por ser parte!
          </Text>
        )}
        <br />
        <br />
        {isArtist ? (
          <ArtistForm handleCreate={handleCreate} />
        ) : (
          <VolunteerForm handleCreate={handleCreate} />
        )}
            <SuccessContainer show={success}>
            <Title>Tu información fue registrada con exito!</Title>
            <Button onClick={() => setSuccess()}>Volver</Button>
          </SuccessContainer>
      </CenterContainer>
    </Container>
  )
}
const SuccessContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index:99;
  ${props =>
    props.show
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`
const BottomContainer = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* -webkit-align-items: center; */
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 500px 1fr;
`

const Info = styled.div`
  padding: 16px;
  margin-right: 48px;
  background: white;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  max-width: 600px;
  border-radius: 10px;
  align-self: flex-start;
`
const ArtistForm = ({ handleCreate }) => {
  return (
    <Formik
      initialValues={{
        type: "artist",
        name: "",
        email: "",
        phoneNumber: "",
        country: "Uruguay",
        industry: "",
        problem: "",
        solution: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Requerido"),
        email: Yup.string()
          .email("Debes ingresar un email valido")
          .required("Requerido"),
        phoneNumber: Yup.string("Debes ingresar un telefono valido").required(
          "Requerido"
        ),
        country: Yup.string().required("Requerido"),
        problem: Yup.string().required(
          "Necesitamos conocer tu situacion para ayudarte!"
        ),
        solution: Yup.string(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await handleCreate(values)
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <FormContainer>
          <Field
            variant="outlined"
            component={TextField}
            name="name"
            label="Nombre completo"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="email"
            label="Email"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="phoneNumber"
            label="Número de teléfono"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="country"
            
            label="País"
          />
          <br />
          <FormControl variant="outlined">
            <InputLabel htmlFor="industry-simple">
              Sector de actividad creativo
            </InputLabel>
            <Field
              component={Select}
              name="industry"
              inputProps={{
                id: "industry-simple",
              }}
            >
              {[
                {
                  label: "Artes escénicas (teatro, danza)",
                  value: "Artes escénicas (teatro, danza)",
                },
                { label: "Editorial", value: "Editorial" },
                { label: "Audiovisual", value: "Audiovisual" },
                { label: "Música", value: "Música" },
                { label: "Diseño", value: "Diseño" },
                { label: "Arte visual", value: "Arte visual" },
                { label: "Otra", value: "Otra" },
              ].map(({ value, label }) => (
                <MenuItem value={value}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="problem"
            multiline
            rows="4"
            label="Contanos sobre tu situación"
            placeholder="Contanos tus problemas, necesidades o dudas"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="solution"
            multiline
            rows="4"
            label="¿Ya implementaste alguna medida?"
            placeholder="Contanos tus ideas, propuestas, intentos o dudas"
          />
          {isSubmitting && <LinearProgress />}
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
            style={{ padding: 8, fontSize: 16, fontWeight: "bold" }}
          >
            Enviar
          </Button>
        </FormContainer>
      )}
    </Formik>
  )
}
const VolunteerForm = ({ handleCreate }) => {
  return (
    <Formik
      initialValues={{
        type: "volunteer",
        name: "",
        email: "",
        phoneNumber: "",
        country: "Uruguay",
        solution: "",
        participates: false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Requerido"),
        email: Yup.string()
          .email("Debes ingresar un email valido")
          .required("Requerido"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await handleCreate(values)
        setSubmitting(false)
        resetForm();

      }}
    >
      {({ submitForm, isSubmitting }) => (
        <FormContainer>
          <Field
            variant="outlined"
            component={TextField}
            name="name"
            label="Nombre completo"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="email"
            label="Email"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="age"

            label="Edad"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="country"

            label="País"
          />
          <br />
          <Field
            variant="outlined"
            component={TextField}
            name="solution"
            multiline
            rows="4"
            label="Comentarios"
            placeholder="Contanos tus ideas, dudas, preguntas, etc"
          />
          <br />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="participates"
            Label={{ label: "Quiero participar de la ideación!" }}
            style={{
              marginLeft: 4,
            }}
          />

          {isSubmitting && <LinearProgress />}
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
            style={{ padding: 8, fontSize: 16, fontWeight: "bold" }}
          >
            Enviar
          </Button>
        </FormContainer>
      )}
    </Formik>
  )
}
const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    width: 100%;
  }
`
const Container = styled.div`
  background: white;
`
const CenterContainer = styled.div`
  max-width: 1100px;
  position:relative;
  margin: auto;
  width: 100%;
  padding: 80px 32px;
  @media (max-width: 600px) {
    padding: 32px 16px;
  }
`
const Text = styled.p`
  color: rgba(0, 0, 0, 0.8);
  font-weight: 200;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 45px;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`

export default RegistrationForm
