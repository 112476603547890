import React, { useEffect } from "react"
import styled, { createGlobalStyle } from "styled-components"
import SEO from "../components/seo"
import HeroBanner from "../components/HeroBanner"
import Form from "../components/Form"
import Info from "../components/Info"
import Footer from "../components/Footer"
import Header from "../components/Header"
import AboutUs from "../components/AboutUs"
import { ThemeProvider } from "@material-ui/core"
import theme from "../theme"

const IndexPage = props => {
  useEffect(() => {
    if (document) {
      const route = window.location.hash
      if (route) {
        const elem = document.querySelector(route)
        if (elem) elem.scrollIntoView()
      }
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyle />
        <SEO title="Inicio" lang="es" />
        <Header />
        <HeroBanner {...props} />
        <Info />
        <Form />
        <AboutUs />
        <Footer />
      </Container>
    </ThemeProvider>
  )
}

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing:border-box;    
    font-family: 'Raleway', Arial;
    margin: 0;
    padding: 0;
    outline:none;
  }
  html{ scroll-behavior: smooth;}
`

export default IndexPage
