import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button, CenterContainer, Text } from "../components"
const Speakers = () => {
  return (
    <Container id="info">
      <CenterContainer>
        <Text>
          <strong>Propósito</strong>
          <br />
          <br />
          #LaFunciónDebeContinuar tiene como propósito visibilizar el sector
          creativo y ayudar en las dificultades que están atravesando en sus
          negocios ante la aparición del COVID-19.
          <br />
          <br />
          <strong>¿Sos artista y necesitas ayuda?</strong>
          <br />
          <br />
          Si tu futuro es incierto por el COVID-19, te invitamos a contarnos tu
          problemática y encontrar juntos una idea para afrontar tus desafíos.
          <br /><br/>
          <a href="#artista">
            <Button>Quiero saber más </Button>
          </a>
          <br/>
          <br/>
          <br/>
          <strong>El arte salva</strong>
          <br />
          <br />
          Si en esta cuarentena estás siendo contenido por música, series, películas, videos, clases online… ¡Hacé que la función continue!
          <br/><br/>
          
          <a href="#interesado">
            <Button>Quiero ayudar</Button>
          </a>
          <br/>
          <br/>
          <br/>
          <strong>¡No estamos solos!</strong>
        </Text>
      </CenterContainer>
    </Container>
  )
}
const Container = styled.div`
  background: #f4f3f5;
`
export default Speakers
