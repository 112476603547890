import styled from "styled-components"
import React from "react"

export const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  border: 0;
  border-radius: 5px;
  padding: 8px 16px;
  background: #ff5154;
  color: white;
  box-shadow: 5px 5px 3 0px 0 rgba(253, 163, 77, 0.2);
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px 40px 0 rgba(253, 163, 77, 0.3);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const Text = styled.p`
  color: rgba(0, 0, 0, 0.8);
  font-weight: 200;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`



export const CenterContainer = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;

  padding: 80px 32px;

  @media (max-width: 600px) {
    padding: 32px 16px;
  }
`
