import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette:{
        primary:{
            main:"#ff5154"
        },
        secondary:{
            main:"#ff5154"
        }
    },
    overrides:{MuiButton:{
        root:{
            borderRadius: 40
        }
    }}
    
  });
  
  export default theme;