import * as React from "react"
import styled, { css } from "styled-components"
import { Formik, Form, Field } from "formik"
import { Button } from "../components"
import { TextField, CheckboxWithLabel, Select } from "formik-material-ui"
import { useMutation } from "@apollo/client"
import * as Yup from "yup"
import gql from "graphql-tag"
const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`

const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($data: LeadCreateInput!) {
    createLead(data: $data) {
      id
      name
      email
      phoneNumber
      type
      country
      phoneNumber
      problem
      solution
      participates
    }
  }
`
const Footer = () => {
  const [success, setSuccess] = React.useState();
  const [createLead] = useMutation(CREATE_LEAD_MUTATION)
  const handleCreate = async data => {
    console.log("CREATE", { data })
    const newLead = await createLead({ variables: { data } })
    setSuccess(newLead)
    return newLead
  }
  return (
    <Container>
      <CenterContainer>
        <InnerContainer id="compartir">
          {/* <Title>CONTACTO</Title> */}
      
          <Title>Enterate de todas nuestras noticias</Title>
          <Formik
            initialValues={{
              type: "subscribe",
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Debes ingresar un email valido")
                .required("Requerido"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              await handleCreate(values)
              setSubmitting(false)
              resetForm()
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <FormContainer>
                <Field
                  fullWidth
                  variant="outlined"
                  component={TextField}
                  name="email"
                  label="Email"
                  style={{ marginRight: 24 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitForm()}
                  disabled={isSubmitting}
                >
                  Subscribirse
                </Button>
              </FormContainer>
            )}
          </Formik>
          <Links>
            {/* <Link target="_blank" rel="noreferrer" href="https://cie.ort.edu.uy">
            <SocialIcon
              alt="Social Web"
              src={require("../images/social/web.png")}
            />
          </Link> */}
            <Link
              target="_blank"
              rel="noreferrer"
              href="mailto:hola@lafunciondebecontinuar.com"
            >
              <SocialIcon
                alt="Social Email"
                src={require("../images/social/email.png")}
              />
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/LaFuncionDebeContinuarUY"
            >
              <SocialIcon
                alt="Social Facebook"
                src={require("../images/social/facebook.png")}
              />
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/lafunciondebecontinuaruy/"
            >
              <SocialIcon
                alt="Social Instagram"
                src={require("../images/social/instagram.png")}
              />
            </Link>
            {/* <Link
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/cie_ort"
          >
            <SocialIcon
              alt="Social Twitter"
              src={require("../images/social/web.png")}
            />
          </Link> */}
          </Links>
          <a target="_blank" href="https://bit.ly/2UrlXeF">
            <CopyRight>© 2020 Designed by ML</CopyRight>
          </a>
          <SuccessContainer show={success}>
            <Title>Tu información fue registrada con exito!</Title>
            <Button onClick={() => setSuccess()}>Volver</Button>
          </SuccessContainer>
        </InnerContainer>
      </CenterContainer>
    </Container>
  )
}
const SuccessContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index:99;
  ${props =>
    props.show
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-top: 32x;
  margin-bottom: 16px;
`

const InnerContainer = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const SocialIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
const Container = styled.div`
  width: 100%;
`
const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Link = styled.a`
  border-radius: 50%;
  padding: 8px;

  height: 56px;
  width: 56px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
`
const CopyRight = styled.p`
  font-size: 11px;
  text-align: center;
  padding: 16px;
`

const CenterContainer = styled.div`
  max-width: 1100px;
  padding: 32px;
  margin: auto;
  width: 100%;
  padding: 32px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
export default Footer
