import React, { useEffect, useState, useCallback } from "react"
import styled, { css } from "styled-components"
import isotipo from "../images/isotipo.png"
import { Link } from "gatsby"
import { HamburgerArrow } from "react-animated-burgers"

const links = ["artista", "interesado", "compartir", "agenda"]
const Header = () => {
  const [show, setShow] = useState(typeof window != 'undefined' && window.scrollY != 0)
  const [showBtn, setShowBtn] = useState(false)
  const [active, setActive] = useState("")
  const handler = useCallback(
    evt => {
      const scrollY = window.scrollY
      if (!show && scrollY > 120) {
        setShow(true)
      } else if (show && scrollY < 120) {
        setShow(false)
      }
      if (!showBtn && scrollY > 700) {
        setShowBtn(true)
      } else if (showBtn && scrollY < 700) {
        setShowBtn(false)
      }

      links.forEach(id => {
        const isActivePage = window.location.href.includes(id)
        if (isActivePage) setActive(id)
        const elem = document.getElementById(id)
        
        if (elem) {
          if (
            elem.offsetTop <= scrollY &&
            elem.offsetTop + elem.offsetHeight >= scrollY
          ) {
            if (id !== active) {
              setActive(id)
            }
          }
        }
      })
    },
    [show]
  )
  useEffect(() => {
    window.addEventListener("scroll", handler, { passive: true })
    handler()
    return () => {
      window.removeEventListener("scroll", handler)
    }
  }, [show])

  const [showMenu, setShowMenu] = useState(false)
  return (
    <Container show={show}>
      <InnerContainer show={show} showMenu={showMenu}>
        <HeaderLink href="/#home">
          <LogoContainer>
            <Logo show={show} src={isotipo} />
            <TextLogo show={show}>La funcion debe continuar</TextLogo>
          </LogoContainer>
        </HeaderLink>
        <Links show={showBtn} showMenu={showMenu}>
          <HeaderLink onClick={() => setShowMenu(false)} href="/#artista">
            <LinkItem active={active == "artista"}>
              Recibi ayuda
            </LinkItem>
          </HeaderLink>
          <HeaderLink onClick={() => setShowMenu(false)} href="/#interesado">
            <LinkItem active={active == "interesado"}>
              Sumate a colaborar
            </LinkItem>
          </HeaderLink>
          <HeaderLink onClick={() => setShowMenu(false)} href="/#compartir">
            <LinkItem active={active == "compartir"}>Corre la voz</LinkItem>
          </HeaderLink>
        </Links>
      </InnerContainer>
      <MenuBtn isActive={showMenu} onClick={() => setShowMenu(!showMenu)} />
    </Container>
  )
}

const TextLogo = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  text-transform: uppercase;
  font-size: 24px;
  margin-right: 24px;
  font-weight: bold;
  transition-delay: .3s;
  > * {
    text-decoration: none;
  }

  opacity: 0;
  transform: translateY(-100%);
  transition: 0.3s;
  ${props =>
    props.show &&
    `
    opacity: 1;
    transform: translateY(0%);
    `}

  @media (max-width: 600px) {
    & {
      opacity: 1;
      font-size: 18px;
      transform: translateY(0%);
    }
  }
`

const MenuBtn = ({ onClick, isActive }) => (
  <MenuButton onClick={onClick} isActive={isActive}>
    <HamburgerArrow
      isActive={isActive}
      toggleButton={onClick}
      barColor="#ff5154"
      buttonWidth={24}
    />
  </MenuButton>
)

const MenuButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  padding: 22px 12px;
  outline: none;
  @media (max-width: 600px) {
    display: block;
  }
  transition: transform 0.4s ease-in-out;
  transform: rotateX(00deg);
  ${props =>
    props.isActive &&
    `
  transform: rotateZ(-270deg);
  `}
`

const HeaderLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  @media (max-width: 600px) {
    padding: 8px;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 640px) {
    justify-content: center;
  }
`
const Logo = styled.img`
  align-self: center;
  height: 60px;
  margin-right: 16px;
  transition: 0.3s ease-in-out;
  ${props =>
    props.show
      ? css`
          opacity: 1;
          height: 32px;
        `
      : css`
          opacity: 1;
          transform: translateY(0px);
          
        `}

  @media(max-width:600px) {
    height: 32px;
  }
`
const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  // background: ${props => (props.show ? `#ffc4ac` : `white`)};
  background:white;
  ${props =>
    props.show &&
    css`
      box-shadow: 0px -10px 50px 0 rgba(0, 0, 0, 0.2);
    `}
  transition: 0.4s ease-in-out;
  z-index: 999999;
  @media (max-width: 600px) {
    background: transparent;
    box-shadow: none;
  }
`
const InnerContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 32px;
  transition: 0.4s ease-in-out;
  justify-content: space-between;
  ${props =>
    props.show
      ? `
        padding: 16px 32px;
    `
      : `padding: 16px 32px;
    `}

  margin: auto;
  display: flex;

  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    background: white;
  }

  @media (max-width: 600px) {
    transform: translateX(0px);
    padding: 16px;
    flex-direction: column;
    align-items:flex-start;
    /* transform: translateY(-100%); */
    ${props =>
      props.showMenu &&
      `
      transform:translateY(0%);
   
    `}
  }
`
const LinksInner = styled.div``
const Links = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  transition: 0.4s ease-in-out;
  // transform: translateX(${props => (props.show ? 0 : 180)}px);
  @media (max-width: 600px) {
    z-index:99999;
    position: absolute;
  
    transition-delay: 0.1s;
    flex-direction: column;
    transform: translateY(-200%);
    width: 100%;
    left: 0;
    top: 62px;
    background: white;
    padding: 8px;
    border-bottom: 1px solid #0000002e;
    ${props =>
      props.showMenu &&
      css`
      opacity: 1;
      transform:translateY(0%);
    `}
  }
`
const LinkItem = styled.span`
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 16px;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  transition: 0.4s ease-in-out;
  border-bottom-width: 2px;
  &:hover {
    border-color: #ff5154;
  }
  ${props =>
    props.active &&
    `
    border-color: #ff5154;
  `}
`
const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  border: 0;
  border-radius: 50px;
  padding: 8px 32px;
  background-image: linear-gradient(to right, #ff5154 0%, #fd6355 100%);
  color: rgba(0, 0, 0, 0.8);
  box-shadow: 3px 3px 30px 0 rgba(253, 163, 77, 0.2);
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  margin-left: 16px;
  opacity: ${props => (props.show ? 1 : 0)};
  @media (max-width: 600px) {
    opacity: 1;
  }
`
export default Header
