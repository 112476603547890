import React from "react"
import styled from "styled-components"
import { Text } from "../components"
const Footer = () => (
  <Container>
    <CenterContainer>
      <InnerContainer id="compartir">
        <Title>QUIENES SOMOS</Title>
        <Text style={{ color: "white" }}>
          #LaFunciónDebeContinuar es una iniciativa solidaria, organizada por un
          grupo de emprendedores que se juntaron para ayudar a afrontar el
          impacto del #Covid19 en la comunidad creativa.
        </Text>
        <Links>
          <Link href="https://www.linkedin.com/in/in%C3%A9s-levrero-41bb14113">Inés Levrero</Link>
          <Link href="https://www.linkedin.com/in/javier-fern%C3%A1ndez-raggio-9a658613/">Javier Fernández</Link>
          <Link href="https://www.instagram.com/belpozzi">María Belén Pozzi</Link>
          <Link href="https://maxtechera.me/">Maximiliano Techera</Link>
          <Link href="https://www.linkedin.com/in/valentinatechera/">Valentina Techera</Link>
        </Links>
      </InnerContainer>
    </CenterContainer>
  </Container>
)

const Title = styled.h1`
  color: white;
  margin-bottom: 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 45px;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`
const InnerContainer = styled.div`
  margin: auto;
`
const SocialIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
const Container = styled.div`
  width: 100%;
  background: #31273b;
`
const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:16px;
`
const Link = styled.a`
  margin: 16px 0px ;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  font-weight: bold;
  color: white;
  text-decoration:none;
  &:hover {
    opacity: 1;
  }
`
const CopyRight = styled.p`
  font-size: 11px;
  text-align: center;
  padding: 16px;
`

const CenterContainer = styled.div`
  max-width: 1100px;
  padding: 32px;
  margin: auto;
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
export default Footer
